


















































































import Vue from "vue";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import SelectDegree from "@/components/modules/institute/SelectNationalDegree.vue";
import { InstituteType } from "@/interfaces/modules/institute/syllabi_comparison";
import { mapActions, mapMutations } from "vuex";
import {
  DEGREE_GAP_LOADING,
  DEGREE_JOB_COMPARISON,
  DEGREE_JOB_FILE_COMPARISON,
  SELECTED_NATIONAL_INSTITUTE
} from "@/store/modules/institute/constants";
import { Institutes } from "@/interfaces/data_objects/institutes";
import { NationalJobComparisonChoice } from "@/interfaces/modules/institute/national_job_comparison_choice";
import { Programs } from "@/interfaces/data_objects/programs";

export default Vue.extend({
  name: "NationalJobComparisonChoice",
  computed: {
    InstituteType() {
      return InstituteType;
    }
  },
  components: { SelectDegree, GoBackHeader },
  data(): NationalJobComparisonChoice {
    return {
      select_degree_form: false,
      national_institute: null,
      national_institute_program: null,
      selected_degree_file: null,
      selected_degree_content: ""
    };
  },
  methods: {
    ...mapActions("institute", {
      fetch_degree_job_comparison: DEGREE_JOB_COMPARISON,
      fetch_degree_job_file_comparison: DEGREE_JOB_FILE_COMPARISON
    }),
    ...mapMutations("institute", {
      set_national_institute: SELECTED_NATIONAL_INSTITUTE,
      set_degree_gap_loading: DEGREE_GAP_LOADING
    }),
    /**
     * Function to update selected institute
     * @param value => selected institute
     */
    update_selected_institute(value: Institutes | null) {
      this.national_institute = value;
    },
    /**
     * Function to update selected program
     * @param {Programs | null } value => selected program & null (if program not selected)
     */
    update_selected_program(value: Programs | null) {
      this.national_institute_program = value;
    },
    update_selected_degree_file(value: File | null) {
      this.selected_degree_file = value;
    },
    update_selected_degree_content(value: string) {
      this.selected_degree_content = value;
    },
    async submit_form() {
      const selected_national_institute = {
        national_institute: this.national_institute,
        national_institute_program: this.national_institute_program,
        selected_national_degree_content: this.selected_degree_content,
        selected_national_degree_file: this.selected_degree_file
      };
      this.set_degree_gap_loading(true);
      this.set_national_institute(selected_national_institute);
      // If user selected degree file and institute
      if (this.selected_degree_file && this.national_institute) {
        const upload_program_form = new FormData();
        upload_program_form.append(
          "program_file",
          this.selected_degree_file as never
        );
        upload_program_form.append("type", "file");
        upload_program_form.append(
          "ref_institute_id",
          this.national_institute.id.toString()
        );
        this.fetch_degree_job_file_comparison(upload_program_form);
      }
      // If user selected institute & degree content
      else if (this.national_institute && this.selected_degree_content) {
        const upload_program_form = new FormData();
        upload_program_form.append(
          "degree_content",
          JSON.stringify(this.selected_degree_content)
        );
        upload_program_form.append("type", "text");
        upload_program_form.append(
          "ref_institute_id",
          this.national_institute.id.toString()
        );
        this.fetch_degree_job_file_comparison(upload_program_form);
      }
      // If user selected institute & program
      else if (this.national_institute && this.national_institute_program) {
        const payload = {
          ref_institute_id: this.national_institute.id,
          ref_program_id: this.national_institute_program.id
        };
        this.fetch_degree_job_comparison(payload);
      }
      // this.fetch_degree_job_comparison();
      await this.$router.push("/institute/comparison/national-job/result");
    }
  }
});
